import React from 'react'
import img01 from '../assets/gallery/img-1.jpeg'
import img02 from '../assets/gallery/img-2.jpeg'
import img03 from '../assets/gallery/img-3.jpeg'
import img04 from '../assets/gallery/img-4.jpeg'
import img05 from '../assets/gallery/img-5.jpeg'
import img06 from '../assets/gallery/img-6.jpeg'
import img07 from '../assets/gallery/img-7.jpeg'
import img08 from '../assets/gallery/img-8.jpeg'
import img09 from '../assets/gallery/img-9.jpeg'
import img10 from '../assets/gallery/img-10.jpeg'
import img11 from '../assets/gallery/img-11.jpeg'
import img12 from '../assets/gallery/img-12.jpeg'
import img13 from '../assets/gallery/img-13.jpeg'
import img14 from '../assets/gallery/img-14.jpeg'
import img15 from '../assets/gallery/img-15.jpeg'
import img16 from '../assets/gallery/img-16.jpeg'
import img17 from '../assets/gallery/img-17.jpeg'
import img18 from '../assets/gallery/img-18.jpeg'
import img19 from '../assets/gallery/img-19.jpeg'
import img20 from '../assets/gallery/img-20.jpeg'
import img21 from '../assets/gallery/img-21.jpeg'
import img22 from '../assets/gallery/img-22.jpeg'
import img23 from '../assets/gallery/img-23.jpeg'
import img24 from '../assets/gallery/img-24.jpeg'
import img25 from '../assets/gallery/img-25.jpeg'
import img26 from '../assets/gallery/img-26.jpeg'
import img27 from '../assets/gallery/img-27.jpeg'
import img28 from '../assets/gallery/img-28.jpeg'
import thumb01 from '../assets/gallery/img-1.jpeg'
import thumb02 from '../assets/gallery/img-2.jpeg'
import thumb03 from '../assets/gallery/img-3.jpeg'
import thumb04 from '../assets/gallery/img-4.jpeg'
import thumb05 from '../assets/gallery/img-5.jpeg'
import thumb06 from '../assets/gallery/img-6.jpeg'
import thumb07 from '../assets/gallery/img-7.jpeg'
import thumb08 from '../assets/gallery/img-8.jpeg'
import thumb09 from '../assets/gallery/img-9.jpeg'
import thumb10 from '../assets/gallery/img-10.jpeg'
import thumb11 from '../assets/gallery/img-11.jpeg'
import thumb12 from '../assets/gallery/img-12.jpeg'
import thumb13 from '../assets/gallery/img-13.jpeg'
import thumb14 from '../assets/gallery/img-14.jpeg'
import thumb15 from '../assets/gallery/img-15.jpeg'
import thumb16 from '../assets/gallery/img-16.jpeg'
import thumb17 from '../assets/gallery/img-17.jpeg'
import thumb18 from '../assets/gallery/img-18.jpeg'
import thumb19 from '../assets/gallery/img-19.jpeg'
import thumb20 from '../assets/gallery/img-20.jpeg'
import thumb21 from '../assets/gallery/img-21.jpeg'
import thumb22 from '../assets/gallery/img-22.jpeg'
import thumb23 from '../assets/gallery/img-23.jpeg'
import thumb24 from '../assets/gallery/img-24.jpeg'
import thumb25 from '../assets/gallery/img-25.jpeg'
import thumb26 from '../assets/gallery/img-26.jpeg'
import thumb27 from '../assets/gallery/img-27.jpeg'
import thumb28 from '../assets/gallery/img-28.jpeg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'


const images01 = [
  {
    id: '2',
    source: img02,
    thumbnail: thumb02,
    caption: 'Panneau publicitaire PLÉXIGLASS AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en aluminium avec lumière en neon',
  },
  {
    id: '3',
    source: img03,
    thumbnail: thumb03,
    caption: 'Panneau publicitaire LED AGADIR',
    description: 'Panneau lumineux avec logo découpé en alucobande ou plexiglas, sud impression agadir',
  },
  {
    id: '1',
    source: img01,
    thumbnail: thumb01,
    caption: 'Panneau publicitaire ROUTE AGADIR',
    description: 'les panneaux publicitaires lumineux Agadir',
    landscape: true,
  },
  {
    id: '4',
    source: img04,
    thumbnail: thumb04,
    caption: 'Panneau publicitaire ROUTE AGADIR',
    description: 'les panneaux publicitaires lumineux Agadir, sud impression agadir',
    landscape: true,
  },
  {
    id: '5',
    source: img05,
    thumbnail: thumb05,
    caption: 'Panneau publicitaire Sud impression AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en aluminium avec lumière en neon - sud impression agadir',
  },
  {
    id: '6',
    source: img06,
    thumbnail: thumb06,
    caption: 'Panneau publicitaire LED AGADIR imprimerie',
    description: 'Panneau lumineux avec logo découpé en alucobande ou plexiglas, sud impression agadir',
  },
  {
    id: '7',
    source: img07,
    thumbnail: thumb07,
    caption: 'Panneau publicitaire BACKLIGHT AGADIR imprimerie',
    description: 'Panneau et enseignes publicitaires en lettres reliefs, sud impression agadir',
    landscape: true,
  },
  {
    id: '8',
    source: img08,
    thumbnail: thumb08,
    caption: 'Panneau publicitaire BACH AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux, sud impression agadir',
  },
  {
    id: '9',
    source: img09,
    thumbnail: thumb09,
    caption: 'Panneau publicitaire ALUCOBOND AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux, sud impression agadir',
  },
  {
    id: '10',
    source: img10,
    thumbnail: thumb10,
    caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led, sud impression agadir',
    landscape: true,
  },
  {
    id: '11',
    source: img11,
    thumbnail: thumb11,
    caption: 'Panneau publicitaire BACKLIGHT AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs, sud impression agadir',
    landscape: true,
  },
  {
    id: '12',
    source: img12,
    thumbnail: thumb12,
    caption: 'Panneau publicitaire BACH AGADIR imprimerie',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux, sud impression agadir',
  },
  {
    id: '13',
    source: img13,
    thumbnail: thumb13,
    caption: 'Panneau publicitaire ALUCOBOND AGADIR imprimerie',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux, sud impression agadir',
  },
  {
    id: '14',
    source: img14,
    thumbnail: thumb14,
    caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR sud impression',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led, sud impression agadir',
    landscape: true,
  },
]

// const images02 = [
//   {
//     id: '4',
//     source: img04,
//     thumbnail: thumb04,
//     caption: 'Panneau publicitaire ROUTE AGADIR',
//     description: 'les panneaux publicitaires lumineux Agadir',
//     landscape: true,
//   },
//   {
//     id: '5',
//     source: img05,
//     thumbnail: thumb05,
//     caption: 'Panneau publicitaire PLÉXIGLASS AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en aluminium avec lumière en neon',
//   },
//   {
//     id: '6',
//     source: img06,
//     thumbnail: thumb06,
//     caption: 'Panneau publicitaire LED AGADIR',
//     description: 'Panneau lumineux avec logo découpé en alucobande ou plexiglas',
//   },
// ]

// const images03 = [
//   {
//     id: '7',
//     source: img07,
//     thumbnail: thumb07,
//     caption: 'Panneau publicitaire BACKLIGHT AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs',
//     landscape: true,
//   },
//   {
//     id: '8',
//     source: img08,
//     thumbnail: thumb08,
//     caption: 'Panneau publicitaire BACH AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux',
//   },
//   {
//     id: '9',
//     source: img09,
//     thumbnail: thumb09,
//     caption: 'Panneau publicitaire ALUCOBOND AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux',
//   },
//   {
//     id: '10',
//     source: img10,
//     thumbnail: thumb10,
//     caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led',
//     landscape: true,
//   },
// ]

// const images04 = [
//   {
//     id: '11',
//     source: img11,
//     thumbnail: thumb11,
//     caption: 'Panneau publicitaire BACKLIGHT AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs',
//     landscape: true,
//   },
//   {
//     id: '12',
//     source: img12,
//     thumbnail: thumb12,
//     caption: 'Panneau publicitaire BACH AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux',
//   },
//   {
//     id: '13',
//     source: img13,
//     thumbnail: thumb13,
//     caption: 'Panneau publicitaire ALUCOBOND AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux',
//   },
//   {
//     id: '14',
//     source: img14,
//     thumbnail: thumb14,
//     caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led',
//     landscape: true,
//   },
// ]

const images05 = [
  {
    id: '15',
    source: img15,
    thumbnail: thumb15,
    caption: 'Panneau publicitaire TOTEM AGADIR',
    description: 'Signalitique routier Agadir imprimerie',
  },
  {
    id: '16',
    source: img16,
    thumbnail: thumb16,
    caption: 'Panneaux publicitaire NÉON AGADIR PRINTING',
    description: 'Agence d\'installation des panneaux publicitaire Agadir imprimerie',
  },
  {
    id: '17',
    source: img17,
    thumbnail: thumb17,
    caption: 'Panneaux publicitaire LUMINEUX AGADIR IMPRIMERIE PRINT',
    description: 'IMPRIMER EN LIGNE Agadir imprimerie.',
    landscape: true,
  },
  {
    id: '18',
    source: img18,
    thumbnail: thumb18,
    caption: 'Panneau publicitaire TOTEM AGADIR IMPRIMERIE PRINT',
    description: 'Signalitique routier Agadir imprimerie',
  },
  {
    id: '19',
    source: img19,
    thumbnail: thumb19,
    caption: 'Panneaux publicitaire NÉON PRINT AGADIR',
    description: 'Agence d\'installation des panneaux publicitaire Agadir imprimerie',
  },
  {
    id: '20',
    source: img20,
    thumbnail: thumb20,
    caption: 'Panneaux publicitaire LUMINEUX AGADIR SUD IMPRESSION',
    description: 'IMPRIMER EN LIGNE Agadir imprimerie.',
    landscape: true,
  },
  {
    id: '21',
    source: img21,
    thumbnail: thumb21,
    caption: 'Panneau publicitaire BACKLIGHT AGADIR SUD IMPRESSION',
    description: 'Panneau et enseignes publicitaires en lettres reliefs à Agadir imprimerie',
    landscape: true,
  },
  {
    id: '22',
    source: img22,
    thumbnail: thumb22,
    caption: 'Panneau publicitaire BACH IMPRESSION AGADIR',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux, Agadir imprimerie',
  },
  {
    id: '23',
    source: img23,
    thumbnail: thumb23,
    caption: 'Panneau publicitaire ALUCOBOND AGADIR PRINTER',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux, Agadir imprimerie',
  },
  {
    id: '24',
    source: img24,
    thumbnail: thumb24,
    caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR IMPRIMERIE PRINT',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led, Agadir imprimerie',
    landscape: true,
  },
  {
    id: '25',
    source: img25,
    thumbnail: thumb25,
    caption: 'Panneau publicitaire BACKLIGHT AGADIR IMPRIMERIE PRINT',
    description: 'Panneau et enseignes publicitaires en lettres reliefs, Agadir imprimerie',
    landscape: true,
  },
  {
    id: '26',
    source: img26,
    thumbnail: thumb26,
    caption: 'Panneau publicitaire BACH AGADIR IMPRIMERIE PRINT',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux, Agadir imprimerie',
  },
  {
    id: '27',
    source: img27,
    thumbnail: thumb27,
    caption: 'Panneau publicitaire ALUCOBOND AGADIR IMPRIMERIE PRINT',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux, Agadir imprimerie',
  },
  {
    id: '28',
    source: img28,
    thumbnail: thumb28,
    caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR IMPRIMERIE PRINT',
    description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led, Agadir imprimerie',
    landscape: true,
  },
]

// const images06 = [
//   {
//     id: '18',
//     source: img18,
//     thumbnail: thumb18,
//     caption: 'Panneau publicitaire TOTEM AGADIR',
//     description: 'Signalitique routier Agadir',
//   },
//   {
//     id: '19',
//     source: img19,
//     thumbnail: thumb19,
//     caption: 'Panneaux publicitaire NÉON AGADIR',
//     description: 'Agence d\'installation des panneaux publicitaire Agadir',
//   },
//   {
//     id: '20',
//     source: img20,
//     thumbnail: thumb20,
//     caption: 'Panneaux publicitaire LUMINEUX AGADIR',
//     description: 'IMPRIMER EN LIGNE Agadir.',
//     landscape: true,
//   },
// ]

// const images07 = [
//   {
//     id: '21',
//     source: img21,
//     thumbnail: thumb21,
//     caption: 'Panneau publicitaire BACKLIGHT AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs',
//     landscape: true,
//   },
//   {
//     id: '22',
//     source: img22,
//     thumbnail: thumb22,
//     caption: 'Panneau publicitaire BACH AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux',
//   },
//   {
//     id: '23',
//     source: img23,
//     thumbnail: thumb23,
//     caption: 'Panneau publicitaire ALUCOBOND AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux',
//   },
//   {
//     id: '24',
//     source: img24,
//     thumbnail: thumb24,
//     caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led',
//     landscape: true,
//   },
// ]

// const images08 = [
//   {
//     id: '25',
//     source: img25,
//     thumbnail: thumb25,
//     caption: 'Panneau publicitaire BACKLIGHT AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs',
//     landscape: true,
//   },
//   {
//     id: '26',
//     source: img26,
//     thumbnail: thumb26,
//     caption: 'Panneau publicitaire BACH AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou en plexiglas lumineux',
//   },
//   {
//     id: '27',
//     source: img27,
//     thumbnail: thumb27,
//     caption: 'Panneau publicitaire ALUCOBOND AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en forex ou plexiglas lumineux',
//   },
//   {
//     id: '28',
//     source: img28,
//     thumbnail: thumb28,
//     caption: 'Panneau publicitaire DÉCOUPE LASER AGADIR',
//     description: 'Panneau et enseignes publicitaires en lettres reliefs découpées en alucobande ou plexiglas avec lumière en Led',
//     landscape: true,
//   },
// ]

const GalleryPage = () => (
  <Layout>
    <section className="intro">
        <header>
          <h1>Galerie</h1>
          
        </header>
        <div style={{marginLeft: "1rem", marginTop:"2rem", marginRight: "1rem"}}>
          <p>Voici quelques photos de notre travail que nous avons fait jusqu'à présent pour nos clients bien-aimés.</p>
        </div>
    </section>

    <section>
      <div className="content">
        <section>
          <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
            <Gallery
              images={images01.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
          </div>
          <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
            <Gallery
              images={images05.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
          </div>
        </section>

        {/* <section>
          <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
            <Gallery
              images={images03.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
          </div>
          <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
            <Gallery
              images={images04.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
          </div>
        </section>

        <section>
          <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
            <Gallery
              images={images05.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
          </div>
          <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
            <Gallery
              images={images06.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
          </div>
        </section>

        <section>
          <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
            <Gallery
              images={images07.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
          </div>
          <div style={{marginLeft: "1rem", marginRight: "1rem"}}>
            <Gallery
              images={images08.map(
                ({
                  id,
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                  landscape,
                })
              )}
            />
          </div>
        </section> */}
      </div>
    </section>
    
  </Layout>
)

export default GalleryPage
